<template>
  <div class="spinner text-center">
    <a-icon type="loading" class="text-primary spinner__icon" />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.spinner {
  margin: 40px;
  &__icon {
    font-size: 30px;
  }
}
</style>
